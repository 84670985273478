<template>
  <AppModal
    style="backdrop-filter: blur(3px)"
    name="plantModal"
    v-model="openModal"
  >
    <div class="w-full h-screen overflow-y-scroll px-2 flex relative">
      <div
        @click="hideModal"
        class="w-full opacity-0 h-full z-10 absolute top-0 left-0"
      ></div>

      <div class="m-auto flex gap-2">
        <transition name="slide-left" mode="out-in" appear>
          <AppCard
            v-if="showPlantNotes"
            class="bg-gray-50 w-full min-w-[300px] h-fit flex-wrap z-20 max-w-3xl relative"
          >
            <div class="flex flex-col">
              <h2 class="font-bold w-full text-xl">Notes</h2>
              <TextInput multiline class="w-full" v-model="plantNotes" />
              <div class="flex w-full items-center justify-end">
                <AppButton
                  @click="saveNotes"
                  color="primary"
                  class="w-max"
                  :loading="saving"
                >
                  Save
                </AppButton>
              </div>
            </div>
          </AppCard>
        </transition>

        <AppCard class="bg-gray-50 w-fit z-20 max-w-3xl relative">
          <div class="absolute hidden top-0 -right-14 sm:flex flex-col gap-4">
            <div
              v-tippy="{ content: 'Close', placement: 'right' }"
              @click="hideModal"
              class="w-10 h-10 hover:-translate-y-0.5 transition-all duration-300 bg-gray-100 rounded-full flex items-center justify-center"
            >
              <IconPack type="XMark" class="w-5 h-5 text-primary-500" />
            </div>
            <!-- <div
              @click="
                () => {
                  showPlantNotes = !showPlantNotes;
                }
              "
              v-tippy="{ content: 'Notes', placement: 'right' }"
              class="w-10 h-10 hover:-translate-y-0.5 transition-all duration-300 bg-gray-100 rounded-full flex items-center justify-center"
            >
              <IconPack type="Pencil" class="w-5 h-5 text-primary-500" />
            </div> -->
          </div>
          <div class="flex flex-col gap-10">
            <div class="flex flex-col gap-4 items-center">
              <h2 class="font-bold text-xl">{{ plant?.attributes.name }}</h2>
              <div
                class="w-full border p-4 flex flex-col h-fit gap-3 max-w-lg rounded-md mx-auto relative"
              >
                <div class="flex items-center gap-3">
                  <div class="bg-secondary-100 w-fit p-4 rounded-full">
                    <IconPack
                      type="Inverter"
                      class="w-8 h-8 fill-secondary-400"
                    />
                  </div>
                  <p class="text-primary-500 font-bold text-xl">
                    {{
                      system_capacity
                        ? `${system_capacity} kW`
                        : 'Not Specified'
                    }}
                  </p>
                </div>
                <div class="flex items-center gap-3">
                  <div class="bg-emerald-100 w-fit p-4 rounded-full">
                    <IconPack
                      type="Battery"
                      class="w-8 h-8 fill-emerald-500 stroke-emerald-500"
                    />
                  </div>
                  <div class="flex flex-col gap-1">
                    <p class="text-primary-500 font-bold text-xl">
                      {{ battery_ah ? `${battery_ah} Ah` : 'Not Specified' }}
                    </p>
                    <p class="text-gray-500 font-medium italic text-base">
                      Time to charge 0 - 100%:
                      {{
                        battery_ah && battery_max_charge_amps
                          ? Math.round(battery_ah / battery_max_charge_amps, 2)
                          : 0
                      }}h
                    </p>
                  </div>
                </div>
                <AppChip
                  v-if="plantType"
                  class="bg-secondary-400 text-white absolute top-4 right-4"
                >
                  {{ plantType[0].toUpperCase() + plantType.slice(1) }}
                </AppChip>
              </div>
              <p
                v-if="area && plant?.attributes.address"
                class="flex flex-wrap w-full max-w-lg"
              >
                {{ plant?.attributes.address }}
              </p>
              <div class="flex gap-4 items-center w-full justify-between">
                <div class="flex flex-col gap-2">
                  <p class="text-sm font-semibold text-primary-500">
                    Plant Location
                  </p>
                  <SearchableDropdown
                    searchable
                    ref="area"
                    :options="areaOptions"
                    placeholder="Area"
                    class="z-50"
                    v-model="plantArea"
                  >
                    <template #iconLeft>
                      <IconPack
                        type="ChevronDown"
                        class="w-5 h-5 text-gray-500"
                      />
                    </template>
                  </SearchableDropdown>
                </div>
                <div class="flex flex-col gap-2">
                  <p class="text-sm font-semibold text-primary-500">
                    Next Inspection
                  </p>
                  <DateRangePicker
                    :ranges="false"
                    v-model="dateRange"
                    :dateRange="dateRange"
                    auto-apply
                    showDropdowns
                    singleDatePicker="single"
                    controlContainerClass="rounded cursor-pointer hover:bg-gray-100 transition-all duration-300 bg-white py-1.5 px-4 border border-gray-300 shadow-sm w-full text-gray-500"
                  >
                    <template #input>
                      <div class="flex gap-2 items-center">
                        <IconPack
                          type="Calendar"
                          class="w-5 h-5 text-gray-500"
                        />
                        <p class="text-gray-500">Date</p>
                        <div
                          class="flex items-center gap-1"
                          v-if="
                            moment(dateRange.startDate).format(
                              'Do MMM YYYY'
                            ) !== 'Invalid date'
                          "
                        >
                          <span class="text-gray-400">|</span>
                          <p
                            class="text-gray-400 text-sm font-semibold flex items-center gap-1"
                          >
                            <span
                              class="bg-gray-100 py-1 px-2 rounded whitespace-nowrap"
                              >{{
                                moment(dateRange.startDate).format('Do MMM YY')
                              }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </template>
                    <template #footer>
                      <!-- Clear Date filters -->
                      <template
                        v-if="
                          dateRange.startDate !== null &&
                          dateRange.endDate !== null
                        "
                      >
                        <div
                          @click="clearDates"
                          class="p-1 border-t mt-1 flex justify-center"
                        >
                          <p
                            class="hover:bg-gray-100 w-full h-full p-2 text-center text-sm rounded"
                          >
                            Clear Date
                          </p>
                        </div>
                      </template>
                    </template>
                  </DateRangePicker>
                </div>
              </div>
              <div
                v-if="area"
                class="w-full max-w-lg h-40 rounded-md overflow-hidden flex items-center"
              >
                <img
                  :src="area?.map_image"
                  :alt="area?.name"
                  class="object-cover scale-125"
                />
              </div>
            </div>
            <div
              class="w-full -mt-4 sm:-mt-0 flex flex-col-reverse sm:flex-row justify-between flex-wrap gap-4 sm:gap-8 items-center uppercase text-center text-xs font-bold"
            >
              <div class="flex gap-1 items-center">
                <IconPack
                  :type="
                    hasConnectApp
                      ? 'DevicePhoneMobile'
                      : invitedToConnect
                      ? 'Clock'
                      : readyToInvite
                      ? 'PaperAirplane'
                      : 'NoSymbol'
                  "
                  :class="{
                    'text-primary-500': hasConnectApp,
                    'text-secondary-500': invitedToConnect && !hasConnectApp,
                    'text-emerald-500 -rotate-45 -translate-y-0.5':
                      !hasConnectApp && !invitedToConnect && readyToInvite,
                    'text-gray-400':
                      !hasConnectApp && !invitedToConnect && !readyToInvite
                  }"
                  class="w-5 h-5 focus:outline-none shrink-0"
                  :solid="invitedToConnect && !hasConnectApp"
                />
                <p
                  :class="
                    hasConnectApp
                      ? 'text-primary-500'
                      : invitedToConnect
                      ? 'text-secondary-400'
                      : readyToInvite
                      ? 'text-emerald-500'
                      : 'text-gray-400'
                  "
                >
                  {{
                    hasConnectApp
                      ? 'App Connected'
                      : invitedToConnect
                      ? 'Invited'
                      : readyToInvite
                      ? 'Ready for Invite'
                      : 'Not Ready for Invite'
                  }}
                </p>
              </div>
              <a
                :href="`https://pro.solarmanpv.com/station?&id=${plant?.attributes.external_id}`"
                target="_blank"
                v-if="
                  plant?.attributes.integration.data?.attributes.uid ==
                  'solarman-business'
                "
              >
                <AppButton size="sm" class="w-max" customColor="#4299E1"
                  >Solarman
                  <IconPack type="ArrowTopRightOnSquare" class="w-4 h-4 ml-2" />
                </AppButton>
              </a>
              <a
                :href="`https://sunsynk.net/plants/overview/${plant?.attributes.external_id}/2`"
                target="_blank"
                v-if="
                  plant?.attributes.integration.data?.attributes.uid ==
                  'sunsynk'
                "
              >
                <AppButton
                  size="sm"
                  customColor="rgb(240, 183, 74)"
                  class="w-max"
                  >Sunsynk
                  <IconPack type="ArrowTopRightOnSquare" class="w-4 h-4 ml-2" />
                </AppButton>
              </a>
              <div class="w-fit">
                <AppButton
                  size="sm"
                  @click="refresh"
                  color="gray"
                  class="flex gap-2"
                >
                  <IconPack type="ArrowPath" class="w-4 h-4" />
                  Refresh
                </AppButton>
              </div>
            </div>
          </div>
        </AppCard>
      </div>
    </div>
  </AppModal>
</template>

<script>
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import DateRangePicker from 'vue3-daterange-picker';

export default {
  components: {
    DateRangePicker
  },
  async mounted() {
    await this.$store.dispatch('Plants/getAreas');
  },
  data() {
    return {
      openModal: this.modelValue,
      plant: null,
      plantArea: '',
      saving: false,
      dateRange: {
        startDate: this.plant?.attributes.next_inspection
          ? new Date(this.plant?.attributes.next_inspection)
          : null
      },
      areaOptions: [],
      showPlantNotes: false
    };
  },
  computed: {
    system_capacity() {
      return this.plant?.attributes.system_capacity;
    },
    battery_ah() {
      return this.plant?.attributes.battery_ah;
    },
    battery_max_charge_amps() {
      return this.plant?.attributes.battery_max_charge_amps;
    },
    plantType() {
      return this.plant?.attributes.type;
    },
    area() {
      return this.plant?.attributes.area?.data?.attributes;
    },
    hasConnectApp() {
      return this.plant?.attributes.invites?.data.some(
        (invite) => invite.attributes.status === 'completed'
      );
    },
    invitedToConnect() {
      return this.plant?.attributes.invites?.data.some(
        (invite) => invite.attributes.status === 'pending'
      );
    },
    readyToInvite() {
      return this.plant?.attributes.ready_for_reload;
    },
    subscriptionStatus() {
      return this.plant?.attributes.subscription?.data?.attributes.status;
    },
    reloadEnabled() {
      return this.plant?.attributes.reload_enabled;
    },
    areas() {
      return this.$store.getters['Plants/areas'];
    }
  },
  methods: {
    hideModal() {
      this.openModal = false;
    },
    clearDates() {
      this.dateRange = {
        startDate: null
      };
    },
    show(plant, areaOptions) {
      this.plant = plant;
      console.log(this.plant);
      this.openModal = true;
      this.plantArea = this.plant?.attributes.area?.data?.attributes?.name;
      this.dateRange = {
        startDate: this.plant?.attributes.next_inspection
          ? new Date(this.plant?.attributes.next_inspection)
          : null
      };

      if (areaOptions && areaOptions.length > 0) {
        this.areaOptions = areaOptions;
      } else {
        this.areaOptions = ['No Options Available'];
      }
    },

    refresh() {
      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        icon: 'Reload',
        title: 'Refresh Plant',
        subtitle: `${
          this.plant.attributes.reload_not_ready_reason
            ? 'Not Ready Reason'
            : 'Not Ready for Invite'
        }`,
        message: `${
          this.plant.attributes.reload_not_ready_reason
            ? this.plant.attributes.reload_not_ready_reason
            : 'Are you sure you want to refresh this plant?'
        }`,
        color: 'primary'
      });

      reveal();

      onConfirm(() => {
        this.$emit('refresh', this.plant);
        this.hideModal();
      });
    }
  },
  watch: {
    plantArea(newValue) {
      if (newValue && Array.isArray(newValue) && newValue.length > 0) {
        const area = this.areas.find((area) => {
          if (area.attributes.name === newValue[0]) {
            return area;
          }
        });

        this.plant.attributes.area.data = {
          ...area
        };

        this.$store
          .dispatch('Plants/updatePlant', {
            id: this.plant.id,
            data: {
              area: {
                connect: [area.id]
              }
            }
          })
          .then(() => {
            this.$toast.success('Area updated successfully');
          });
      } else if (newValue === '' || newValue?.length === 0) {
        this.$store
          .dispatch('Plants/updatePlant', {
            id: this.plant?.id,
            data: {
              area: {
                disconnect: [this.plant?.attributes.area?.data?.id]
              }
            }
          })
          .then(() => {
            this.$toast.success('Area updated successfully');
            this.plant.attributes.area.data = null;
          });
      }
    },
    dateRange: {
      handler(newValue) {
        const newDate = new Date(newValue.startDate).toISOString();
        const oldDate = new Date(
          this.plant?.attributes.next_inspection
        ).toISOString();

        if (newDate && newDate !== oldDate) {
          this.$store
            .dispatch('Plants/updatePlant', {
              id: this.plant?.id,
              data: {
                next_inspection: newValue.startDate
                  ? newValue.startDate.toISOString()
                  : null
              }
            })
            .then(() => {
              this.$toast.success('Next Inspection Date updated successfully');
              this.plant.attributes.next_inspection = newValue.startDate;
            });
        }
      },
      deep: true
    }
  }
};
</script>
