<template>
  <transition name="scale-quick" mode="out-in" appear>
    <AppCard v-if="!fullPage" class="w-full">
      <template #title> Client </template>
      <template #topRight>
        <div v-if="selectClient" class="flex gap-6 items-center justify-center">
          <AppButton
            @click="showContactModal"
            outline
            class="group"
            type="primary"
          >
            <IconPack
              type="UserPlus"
              class="h-5 w-5 mr-2 text-primary-500 group-hover:text-white"
            />
            <p class="text-primary-500 group-hover:text-white">New Contact</p>
          </AppButton>
          <div
            v-if="skipable"
            @click="skipContact"
            class="p-2 flex items-center hover:text-primary-300 gap-2"
          >
            <p class="">Skip</p>
            <IconPack type="ArrowRight" class="h-5 w-5 mr-2" />
          </div>
        </div>
        <div v-if="selectedContact" class="flex mb-1 gap-4">
          <tippy>
            <div
              @click="swapContact"
              class="hover:bg-gray-200 rounded-full p-1.5 transition-all duration-300"
            >
              <IconPack
                type="ArrowsRightLeft"
                class="h-5 w-5 text-primary-500"
              />
            </div>
            <template #content>
              <p class="text-sm">Change Contact</p>
            </template>
          </tippy>
          <tippy>
            <div
              @click="editContact"
              class="hover:bg-gray-200 rounded-full p-1.5 transition-all duration-300"
            >
              <IconPack type="PencilSquare" class="h-5 w-5 text-primary-500" />
            </div>
            <template #content>
              <p class="text-sm">Edit Contact</p>
            </template>
          </tippy>
        </div>
      </template>
      <AddCard
        @click="selectClient ? this.$emit('swap') : ''"
        v-if="!selectedContact"
        :class="{ 'border-none shadow-none': selectClient }"
        :controls="false"
        v-model:active="selectClient"
      >
        <template v-if="!selectClient" #title> Select Client </template>
        <template #content>
          <TextInput
            v-model="searchQuery"
            class="w-full max-w-sm -mt-6"
            label="Search Client"
            placeholder="Search Client"
          >
            <template #icon>
              <IconPack type="MagnifyingGlass" class="h-5 w-5 text-gray-500" />
            </template>
          </TextInput>
          <!-- Contact Cards -->
          <div
            class="h-full flex flex-col gap-4 p-1 overflow-y-scroll"
            :class="{
              'max-h-[55vh]': skipable,
              'max-h-[30vh]': !skipable
            }"
            ref="contactsContainer"
          >
            <div v-for="(contact, index) in filteredContacts" :key="index">
              <ContactCard
                :condensed="!skipable"
                class="w-full"
                @click="selectContact(contact)"
                clickable
                :contact="contact"
              />
            </div>
            <!-- load more button -->

            <div
              v-if="contacts?.length < totalContacts && !fetching"
              class="w-fit mx-auto my-2"
            >
              <AppButton @click="loadMore" type="primary">
                <p>Load More</p>
              </AppButton>
            </div>
          </div>
          <div
            v-if="fetching"
            class="w-full mt-4 flex items-center justify-center"
          >
            <AppSpinner class="w-7" />
          </div>
        </template>
      </AddCard>
      <transition name="scale" mode="out-in" appear>
        <ContactCard v-if="selectedContact" :contact="selectedContact" />
      </transition>
    </AppCard>
    <div class="flex flex-col gap-4" v-else-if="fullPage">
      <div class="ml-auto flex flex-col gap-3">
        <AppButton
          @click="showContactModal"
          outline
          class="group"
          type="primary"
        >
          <IconPack
            type="UserPlus"
            class="h-5 w-5 mr-2 text-primary-500 group-hover:text-white"
          />
          <p class="text-primary-500 group-hover:text-white">New Contact</p>
        </AppButton>
        <AppButton
          size="xs"
          type="primary"
          :disabled="!filteredContacts?.length"
          v-tippy="{ content: 'Export current view of contacts as CSV' }"
          @click="exportContacts"
        >
          <template #icon>
            <IconPack type="ArrowDownOnSquare" class="w-5 h-5 text-white" />
          </template>
          Export
        </AppButton>
      </div>
      <TextInput
        v-model="searchQuery"
        class="w-full max-w-sm -mt-6"
        label="Search Client"
        placeholder="Search Client"
      >
        <template #icon>
          <IconPack type="MagnifyingGlass" class="h-5 w-5 text-gray-500" />
        </template>
      </TextInput>
      <div v-for="(contact, index) in filteredContacts" :key="index">
        <transition name="scale-quick" mode="out-in">
          <ContactCard
            v-if="!showSkeleton && filteredContacts?.length > 0"
            class="w-full"
            @click="
              () => {
                selectContact(contact);
                editContact();
              }
            "
            clickable
            deletable
            :contact="contact"
            @delete="deleteContact"
          />
          <ContactSkeleton v-else />
        </transition>
      </div>
      <div
        v-if="
          contacts?.length < totalContacts &&
          filteredContacts.length > 0 &&
          !fetching
        "
        class="w-full mx-auto my-2"
      >
        <AppPagination
          :pageCount="pageCount"
          :currentPage="parseInt(currentPage)"
          @previous="loadPreviousPage"
          @next="loadNextPage"
          @goto="gotoPage"
        />
      </div>
      <div v-if="fetching" class="w-full mt-4 flex items-center justify-center">
        <AppSpinner class="w-7" />
      </div>
      <transition name="scale-quick" mode="out-in" appear>
        <div
          v-if="!fetching && filteredContacts.length == 0"
          class="w-full text-gray-500"
        >
          No Contacts Found
        </div>
      </transition>
    </div>
  </transition>

  <NewContactModal
    ref="clientModal"
    v-model="showNewContactModal"
    @newContact="selectContact"
  />
  <ErrorModal ref="errorModal" />
</template>

<script>
import AddCard from '@/components/EW/AddCard.vue';
import ContactCard from '@/components/Contacts/ContactCard.vue';
import NewContactModal from '@/components/Modals/NewContactModal.vue';
import ErrorModal from '@/components/Modals/ErrorModal.vue';
import AppPagination from '@/components/EW/AppPagination.vue';
import ContactSkeleton from '@/components/Contacts/ContactSkeleton.vue';
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import { Tippy } from 'vue-tippy';

export default {
  name: 'AddJobs',
  components: {
    AddCard,
    ContactCard,
    Tippy,
    NewContactModal,
    ErrorModal,
    AppPagination,
    ContactSkeleton
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    },
    skipable: {
      type: Boolean,
      default: false
    },
    fullPage: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'select', 'skip', 'swap'],
  mounted() {
    this.currentPage = 1;
    if (!this.modelValue && this.active) {
      this.fetchContacts();
    }
  },
  data() {
    return {
      fillLocation: false,
      selectClient: this.active,
      currentLimit: 5,
      totalContacts: 0,
      fetching: true,
      selectedContact: this.modelValue,
      showNewContactModal: false,
      filtered: false,
      pageCount: 0,
      perPage: 25,
      showSkeleton: false
    };
  },
  computed: {
    contacts() {
      return this.$store.getters['Contacts/contacts'];
    },
    clientJobs() {
      return this.$store.getters['Jobs/jobs'];
    },
    filteredContacts() {
      let contacts = this.contacts?.filter((contact) => {
        // check first and lastname
        const fullName =
          `${contact.first_name} ${contact.last_name}`.toLowerCase();
        return (
          fullName.includes(this.searchQuery.toLowerCase()) ||
          contact.email.includes(this.searchQuery.toLowerCase()) ||
          contact.cellphone.includes(this.searchQuery.toLowerCase())
        );
      });

      if (!this.filtered) {
        this.fetchContacts(this.searchQuery);
      }

      return contacts;
    },
    currentPage: {
      get() {
        const value =
          parseInt(this.$route.query?.page?.toString()) ||
          localStorage.getItem('currentPlantPage') ||
          1;
        return value == 'NaN' ? 1 : value;
      },
      set(value) {
        if (value == 'NaN') value = 1;
        this.showSkeleton = true;
        this.$router
          .replace({ query: { ...this.$route.query, page: value } })
          .then(() => {
            debounce(() => {
              this.fetchContacts();
              localStorage.setItem('currentPlantPage', value.toString());
            }, 200);
          });
      },
      clientJobs() {
        return this.$store.getters['Jobs/jobs'];
      }
    },
    searchQuery: {
      get() {
        return this.$route.query?.search || '';
      },
      set(value) {
        this.$router.replace({
          query: { ...this.$route.query, search: value }
        });
      }
    }
  },
  methods: {
    loadMore() {
      if (this.contacts.length < this.totalContacts) {
        this.currentLimit += 5;
        this.fetchContacts(this.searchQuery);
      }
    },
    skipContact() {
      this.selectClient = false;
      this.$emit('skip');
    },
    selectContact(contact) {
      this.selectedContact = contact;
      this.selectClient = false;
      this.$emit('update:modelValue', contact);
      this.$emit('select');
    },
    swapContact() {
      this.selectedContact = null;
      this.selectClient = true;
      this.$emit('swap');
    },
    deleteContact(contact) {
      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        icon: 'Trash',
        title: 'Delete Contact',
        message: 'Are you sure you want to delete this contact?',
        color: 'red',
        jobWarning: this.clientJobs.length
      });

      reveal();

      onConfirm(() => {
        this.$store
          .dispatch('Contacts/deleteContact', contact.id)
          .then(() => {
            this.$toast.success('Contact deleted');
            this.selectedContact = null;
            this.fetchContacts();
          })
          .catch(() => {
            this.$toast.error('Failed to delete contact');
          });
      });
    },
    fetchContacts(searchQuery) {
      this.fetching = true;
      const start = (this.currentPage - 1) * this.perPage;
      const limit = this.perPage;
      let filters = null;

      if (searchQuery) {
        const words = searchQuery.split(' ');

        filters = {
          filters: {
            $or: []
          }
        };

        words.forEach((word) => {
          if (word !== '')
            filters.filters.$or.push(
              { first_name: { $containsi: word } },
              { last_name: { $containsi: word } },
              { email: { $containsi: word } },
              { cellphone: { $containsi: word } }
            );
        });
      }

      debounce(() => {
        this.$store
          .dispatch('Contacts/getContacts', {
            start: this.fullPage ? start : 0,
            limit: this.fullPage ? limit : this.currentLimit,
            search: filters
          })
          .then((res) => {
            this.totalContacts = res.data.meta.pagination.total;
            this.pageCount = Math.ceil(this.totalContacts / this.currentLimit);
          })
          .catch((err) => {
            this.$toast.error('Failed to fetch contacts', {
              position: 'bottom',
              onClick: () => {
                this.$refs.errorModal.show(err);
              }
            });
            this.fetching = false;
          })
          .finally(() => {
            this.fetching = false;
            this.filtered = true;
            this.showSkeleton = false;
          });
      }, 500);
    },
    exportContacts() {
      //download filteredPlants as csv
      const csvData = this.filteredContacts.map((contact) => {
        return {
          first_name: contact.first_name,
          last_name: contact.last_name,
          email: contact.email,
          cellphone: contact.cellphone
        };
      });

      const csvRows = [];
      const headers = Object.keys(csvData[0]);
      csvRows.push(headers.join(','));

      for (const row of csvData) {
        const values = headers.map((header) => {
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      }

      const csvContent = csvRows.join('\n');

      const downloadLink = document.createElement('a');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'plants.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    showContactModal() {
      this.$refs.clientModal.clearForm();
      this.showNewContactModal = true;
    },
    editContact() {
      this.showNewContactModal = true;
      this.$refs.clientModal.editContact(this.selectedContact);
    },
    loadPreviousPage() {
      if (this.currentPage > 1) {
        this.fetching = true;
        this.currentPage--;
      }
    },

    loadNextPage() {
      if (this.currentPage < this.pageCount) {
        this.fetching = true;
        this.currentPage++;
      }
    },

    gotoPage(page) {
      if (page === '...') {
        return;
      }
      this.fetching = true;
      this.currentPage = page;
    }
  },
  watch: {
    searchQuery() {
      this.filtered = false;
      this.currentPage = 1;
    },
    modelValue(newVal) {
      this.selectedContact = newVal;
    }
  }
};
let timeout;
const debounce = (func, delay) => {
  clearTimeout(timeout);

  timeout = setTimeout(func, delay);
};
</script> 
