<template>
  <AppModal class="backdrop-blur-sm" v-model="show">
    <div class="bg-white max-w-3xl relative rounded-md flex m-auto mt-10 p-8">
      <div class="absolute top-0 -right-14 flex flex-col gap-4">
        <div
          @click="hideModal"
          class="w-10 h-10 hover:shadow-sm hover:shadow-white hover:-translate-y-0.5 transition-all duration-300 bg-gray-100 rounded-full flex items-center justify-center"
        >
          <IconPack type="XMark" class="w-5 h-5 text-primary-500" />
        </div>
      </div>
      <div
        class="h-full w-full max-h-[80vh] p-2 drop-shadow-sm"
        :class="{
          'overflow-y-scroll': !selectedDocumentType
        }"
      >
        <div class="w-full flex justify-between">
          <div class="w-fit py-2 px-4 rounded-full flex gap-2 items-center">
            <IconPack type="DocumentPlus" class="w-8 h-8 text-primary-500" />
            <p class="font-bold text-xl">Add Document</p>
          </div>
          <div>
            <AppButton @click="createDocument"> Create Document </AppButton>
          </div>
        </div>
        <div
          class="flex transition-all duration-300 gap-2 my-5"
          :class="{
            'max-w-2xl mx-auto': selectedDocumentType
          }"
        >
          <TextInput v-model="label" label="Document Label" class="w-full" />
        </div>
        <!-- search section -->
        <transition name="scale-quick" mode="out-in" appear>
          <div v-if="!selectedDocumentType" class="flex gap-2 my-5">
            <TextInput
              v-model="search"
              placeholder="Search document types"
              label="Document Type"
              class="w-full"
            >
              <template #icon>
                <IconPack
                  type="MagnifyingGlass"
                  class="text-gray-400 w-5 h-5"
                />
              </template>
            </TextInput>
          </div>
        </transition>
        <transition name="scale-quick" mode="out-in" appear>
          <div
            class="flex flex-col gap-5 w-full"
            v-if="
              categories && filteredTemplateDocuments && !selectedDocumentType
            "
          >
            <SelectTemplate
              :category="cat"
              @select="selectDocument"
              :documents="
                filteredTemplateDocuments?.filter(
                  (doc) => doc.attributes?.category?.data?.id == cat?.id
                )
              "
              v-for="cat in categories"
              :key="cat?.id"
            />
          </div>
        </transition>

        <transition name="scale-quick" mode="out-in" appear>
          <div
            v-if="selectedDocumentType"
            class="w-full mx-auto max-w-2xl flex flex-col border border-primary-500 rounded-lg"
          >
            <div
              class="w-full bg-primary-500 gap-2 justify-between rounded-t-md py-2 pl-5 pr-2 font-bold text-white text-center flex items-center"
            >
              <p class="w-full">
                {{
                  selectedDocumentType.attributes.category.data.attributes.title
                }}
              </p>
              <div
                @click="changeDocument"
                v-tippy="{ content: 'Change Document' }"
                class="bg-gray-100 hover:bg-primary-300 text-primary-500 hover:text-white rounded-full p-1.5 transition-all duration-300"
              >
                <IconPack type="ArrowsRightLeft" class="h-5 w-5" />
              </div>
            </div>
            <div class="w-full p-5 font-bold">
              {{ selectedDocumentType.attributes.name }}
            </div>
          </div>
        </transition>

        <transition name="scale-quick" mode="out-in" appear>
          <AppCard v-if="selectedDocumentType" class="my-5 max-w-2xl mx-auto">
            <template #title> Copy values from </template>
            <template #topRight>
              <div class="bg-primary-500 py-1 px-3 rounded-xl">
                <p class="text-white font-semibold">Optional</p>
              </div>
            </template>
            <div class="flex flex-col mt-5 gap-2">
              <p class="text-gray-400 font-normal">
                Select a template
                {{
                  selectedDocumentType?.attributes?.category.data.attributes
                    .title
                }}
                to copy from.
                <span class="text-secondary-500">(Not requred)</span>
              </p>
              <SearchableDropdown
                searchable
                placeholder="Copy from"
                v-model="selectedTemplate"
                :options="loadingTemplates ? ['Loading...'] : templateOptions"
                label="Select Template"
              >
                <template #iconRight>
                  <IconPack type="ChevronDown" class="w-5 h-5 text-gray-400" />
                </template>
              </SearchableDropdown>
            </div>
          </AppCard>
        </transition>
      </div>
    </div>
  </AppModal>
</template>

<script>
import SelectTemplate from '@/components/Documents/SelectTemplate.vue';

export default {
  name: 'AddDocument',
  components: {
    SelectTemplate
  },
  computed: {
    templateDocuments() {
      return this.$store.getters['Documents/types'];
    },
    filteredTemplateDocuments() {
      const templateDocuments = this.templateDocuments.filter(
        (doc) => !doc.attributes.archived
      );

      if (!this.search) {
        return templateDocuments;
      }
      const searchLowerCase = this.search.toLowerCase();
      return templateDocuments.filter((doc) => {
        const name = doc.attributes.name.toLowerCase();
        const subtitle = doc.attributes.subtitle
          ? doc.attributes.subtitle.toLowerCase()
          : '';
        return (
          name.includes(searchLowerCase) || subtitle.includes(searchLowerCase)
        );
      });
    },
    categories() {
      const categories = this.templateDocuments?.map(
        (doc) => doc.attributes?.category?.data
      );
      return categories
        ?.filter((v, i, a) => a.findIndex((t) => t?.id === v?.id) === i)
        .filter((cat) => cat)
        .sort((a, b) => {
          return (
            this.templateDocuments?.filter(
              (doc) => doc.attributes?.category?.data?.id == b?.id
            ).length -
            this.templateDocuments?.filter(
              (doc) => doc.attributes?.category?.data?.id == a?.id
            ).length
          );
        });
    }
  },
  data() {
    return {
      search: '',
      show: false,
      label: '',
      selectedDocumentType: null,
      selectedTemplate: null,
      templateOptions: [],
      loadingTemplates: false
    };
  },
  mounted() {
    if (!this.templateDocuments) {
      this.$store.dispatch('Documents/getDocumentTemplates');
    }
  },
  methods: {
    showModal(title) {
      this.show = true;
      this.search = '';
      this.label = title.split(' - ')[0];
      this.selectedDocumentType = null;
      this.selectedTemplate = null;
    },
    hideModal() {
      this.show = false;
    },
    selectDocument(doc) {
      this.selectedDocumentType = doc;
      this.label = `${this.label} - ${doc.attributes.name}`;
      this.loadingTemplates = true;
      this.$store
        .dispatch('Documents/getDocumentByType', { type: doc.attributes.name })
        .then((res) => {
          this.templateOptions = res.data.data.map((doc) => {
            return {
              ...doc.attributes,
              id: doc.id
            };
          });
        })
        .finally(() => {
          this.loadingTemplates = false;
        });
    },
    changeDocument() {
      this.selectedDocumentType = null;
      this.label = this.label.split(' - ')[0];
    },
    createDocument() {
      if (this.selectedDocumentType) {
        this.$emit('create', {
          label: this.label,
          type: this.selectedDocumentType,
          template: this.selectedTemplate ? this.selectedTemplate[0] : null
        });
        this.hideModal();
      } else {
        this.$toast.error('Please select a document type');
      }
    }
  }
};
</script>
